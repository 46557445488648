//      

const StyleLayer = require('../style_layer');
const FillBucket = require('../../data/bucket/fill_bucket');
const {multiPolygonIntersectsMultiPolygon} = require('../../util/intersection_tests');
const {translateDistance, translate} = require('../query_utils');
const properties = require('./fill_style_layer_properties');

const {
    Transitionable,
    Transitioning,
    PossiblyEvaluated
} = require('../properties');

                                                        
                                                
                                                              
                                                                 

class FillStyleLayer extends StyleLayer {
                                                     
                                                   
                                         

    constructor(layer                    ) {
        super(layer, properties);
    }

    recalculate(parameters                      ) {
        this.paint = this._transitioningPaint.possiblyEvaluate(parameters);

        if (this._transitionablePaint.getValue('fill-outline-color') === undefined) {
            this.paint._values['fill-outline-color'] = this.paint._values['fill-color'];
        }
    }

    createBucket(parameters                     ) {
        return new FillBucket(parameters);
    }

    queryRadius()         {
        return translateDistance(this.paint.get('fill-translate'));
    }

    queryIntersectsFeature(queryGeometry                     ,
                           feature                   ,
                           geometry                     ,
                           zoom        ,
                           bearing        ,
                           pixelsToTileUnits        )          {
        const translatedPolygon = translate(queryGeometry,
            this.paint.get('fill-translate'),
            this.paint.get('fill-translate-anchor'),
            bearing, pixelsToTileUnits);
        return multiPolygonIntersectsMultiPolygon(translatedPolygon, geometry);
    }
}

module.exports = FillStyleLayer;
