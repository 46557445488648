//      

module.exports = require('../style-spec/validate_style.min');

                                           

module.exports.emitErrors = function (emitter         , errors                           ) {
    if (errors && errors.length) {
        for (const {message} of errors) {
            emitter.fire('error', { error: new Error(message) });
        }
        return true;
    } else {
        return false;
    }
};
